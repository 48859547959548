import React from 'react'
import { SimpleImg } from 'react-simple-img'
import PropTypes from 'prop-types'

const OverviewSetCard = ({
  title,
  slug,
  handleError
}) => {
  const safeRequire = (slug, size = '') => {
    try {
      return require(`../images/categories/${slug}/full-set-overview-${slug}${size}.png`)
    } catch (error) {
      console.error(error)

      handleError(error)
    }
  }

  return (
    <div className={`overview__set-card ${slug}`}>
      <div className="set-card__inner">
        <p className="set-card__title">{title}</p>
        <SimpleImg
          src={safeRequire(slug, '@2x')}
          srcSet={`
            ${safeRequire(slug, '@2x')} 400w,
            ${safeRequire(slug)} 200w
          `}
          sizes="200px"
          alt={`Icons of the icon set ${title}`}
          className="set-card__icon-set"
          placeholder="#FAF5FF"
          height={114}
          animationDuration={0.3}
        />
      </div>
    </div>
  )
}

OverviewSetCard.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  handleError: PropTypes.func.isRequired
}

OverviewSetCard.defaultProps = {
  title: 'Drink',
  slug: 'drink'
}

export default OverviewSetCard
