import React from 'react'
import PropTypes from 'prop-types'

// Language
import fullCollectionPrice from '../lang/fullCollectionPrice'

const {
  unit,
  fullPrice,
  discountedPrice
} = fullCollectionPrice

const DealCTA = ({
  type,
  addVariantToCart,
  fullCollectionId
}) => (
  <section className="deal-cta">
    <div className="container-md">
      <div className={`deal-cta-container ${type}`}>
        <div className="left">
          { type === 'default' && (
            <h4>A Jolly good discount</h4>
          )}

          <p className="streamer second">
            Buy Jollycons v2 for a discounted price and instantly receive over 580+ hand-crafted icons, suitable for any project.
          </p>
        </div>

        <div className={`right right-${type}`}>
          <button
            className={`btn cta lg md ${type === 'default' ? 'filled' : 'light'}`}
            onClick={() => addVariantToCart(fullCollectionId)}
            type="button"
          >
            <span>
              Buy Now!
              <span>
                <span className="discount light">
                  {unit}
                  <strong>{fullPrice}</strong>
                </span>
                <span className="discounted price">
                  {`${unit}${discountedPrice}`}
                </span>
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </section>
)

DealCTA.propTypes = {
  type: PropTypes.oneOf(['default', 'dark']).isRequired,
  addVariantToCart: PropTypes.func.isRequired,
  fullCollectionId: PropTypes.string.isRequired
}

export default DealCTA
