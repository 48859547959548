import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Countdown, { formatTimeDelta } from 'react-countdown-now'

/* NOTE: We add +0200 here to tell JS to make the end date use a timezone offset of 2 hours (CET (summer)).
  This makes it so the countdown timer shows the same amount of time left in all timezones.
*/
const DiscountCountdown = ({
  endDate,
  onComplete,
  type
}) => {
  const [isCompletedRan, setIsCompletedRan] = useState(false)

  return (
    <Countdown
      date={`${endDate} +0100`}
      onComplete={onComplete}
      renderer={({ completed, ...date }) => {
        const {
          hours,
          minutes,
          seconds
        } = formatTimeDelta(date, {
          daysInHours: true,
          zeroPadTime: 2
        })

        if (completed && !isCompletedRan) {
          setIsCompletedRan(true)
          onComplete()
        }

        return (
          <p className="countdown-text">
            {
              type === 'normal' ? (
                `Ends in ${hours}hrs, ${minutes}mins and ${seconds}s`
              ) : (
                `Ends ${hours}h ${minutes}m ${seconds}s`
              )
            }
          </p>
        )
      }}
    />
  )
}

DiscountCountdown.defaultProps = {
  endDate: '21 Aug 2019 12:00:00',
  onComplete: () => { },
  type: 'normal'
}

DiscountCountdown.propTypes = {
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onComplete: PropTypes.func,
  type: PropTypes.oneOf([
    'normal',
    'compact'
  ])
}

export default DiscountCountdown
