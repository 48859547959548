import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// Components
import FileTypesUsp from '../components/FileTypesUsp'
import DealCTA from '../components/DealCTA'
import withContext from '../lib/withContext'
import OverviewSets from '../components/OverviewSets'
import Animations from '../components/Home/Animations'
import GeneralUsps from '../components/GeneralUsps'
import TopSellingCards from '../components/TopSellingCards'
import DiscountBadge from '../components/DiscountBadge'

// Language
import fullCollectionPrice from '../lang/fullCollectionPrice'
import { discountEndDate } from '../lang/discountAction'

const {
  unit,
  fullPrice,
  discountedPrice
} = fullCollectionPrice

const topCardData = [
  {
    colorType: 'rose',
    slug: 'ecommerce',
    title: 'Ecommerce',
    comingSoon: false
  },
  {
    colorType: 'sun',
    slug: 'ui-1',
    title: 'UI 1',
    comingSoon: false
  },
  {
    colorType: 'sea',
    slug: 'ui-2',
    title: 'UI 2',
    comingSoon: false
  }
]

const IndexPage = ({
  fullCollectionId,
  addVariantToCart,
  data,
  showErrorNotification
}) => (
  <div>
    <main className="home animated fadeInPage">
      <header className="header padding-xl" id="main-header">
        <div className="container-md">
          <div className="grid">
            <div className="col-12 home-header-content">
              <div className="home-content">
                <h1 className="dark home-header">
                  Gorgeous icons with a fun
                  <span className="light"> &amp; </span>
                  friendly look
                </h1>
                <p className="streamer home-streamer">
                  {'Jollycons contains over 580+ playful looking icons in eight different '}
                  <a href="#file-types">file formats</a>
                  {' to brighten up your designs.'}
                </p>

                <div className="buttons">
                  <button
                    className="btn primary cta lg"
                    onClick={() => addVariantToCart(fullCollectionId)}
                    type="button"
                    id="home-buy-button"
                  >
                    Buy Now!
                    <span>
                      <span className="discount light">
                        {unit}
                        <strong>{fullPrice}</strong>
                      </span>
                      <span className="discounted price">
                        {`${unit}${discountedPrice}`}
                      </span>
                    </span>
                    </button>

                  <DiscountBadge
                    endDate={discountEndDate}
                    headerText="50% Black Friday discount"
                    type="normal"
                  />
                </div>
              </div>

              <div className="home-animation">
                <Animations />
              </div>

              <div className="buttons mobile">
                <button
                  className="btn primary cta lg"
                  onClick={() => addVariantToCart(fullCollectionId)}
                  type="button"
                  id="home-buy-button"
                >
                  Buy Now!
                  <span>
                    <span className="discount light">
                      {unit}
                      <strong>{fullPrice}</strong>
                    </span>
                    <span className="discounted price">
                      {`${unit}${discountedPrice}`}
                    </span>
                  </span>
                  </button>

                <DiscountBadge
                  endDate={discountEndDate}
                  headerText="50% Black Friday discount"
                  type="normal"
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id="sets-overview">
        <OverviewSets data={data} handleError={showErrorNotification} />
      </section>

      <DealCTA
        type="dark"
        addVariantToCart={addVariantToCart}
        fullCollectionId={fullCollectionId}
      />

      <div id="file-types" />

      <GeneralUsps />

      <section className="types">
        <div className="container-md padding-l">
          <FileTypesUsp />
        </div>
      </section>

      <TopSellingCards
        {...{
          topCardData,
          type: 'home'
        }}
      />

      <DealCTA
        type="default"
        addVariantToCart={addVariantToCart}
        fullCollectionId={fullCollectionId}
      />
    </main>
  </div>
)

IndexPage.propTypes = {
  addVariantToCart: PropTypes.func,
  data: PropTypes.any,
  fullCollectionId: PropTypes.string,
  lineItems: PropTypes.array,
  showErrorNotification: PropTypes.func,
  toggleModalShow: PropTypes.func
}

export const overviewIconSets = graphql`
  query getOverviewIconSets {
    allContentfulIconSet {
      edges {
        node {
          id
          slug
          title
          comingSoon
        }
      }
    }
  }
`

export default withContext(IndexPage)
