import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DiscountCountdown from './DiscountCountdown'

import { isBlackFriday } from '../../lang/discountAction'

const DiscountBadge = ({
  endDate,
  headerText,
  type,
  isHidden,
}) => {
  const [isCompleted, setIsCompleted] = useState(false)
  if (isBlackFriday) {
    if (isCompleted) {
      return null
    }

    return (
      <div className={`discount-badge ${type} ${isHidden ? 'hidden' : ''}`}>
        <div className="discount-badge-header">
          <p>{headerText}</p>
        </div>
        <div className="discount-badge-footer">
          <DiscountCountdown
            endDate={endDate}
            onComplete={() => setIsCompleted(true)}
            type={type}
          />
        </div>
      </div>
    )
  }

  return null

}

DiscountBadge.defaultProps = {
  type: 'normal'
}

DiscountBadge.propTypes = {
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  headerText: PropTypes.string,
  type: PropTypes.oneOf([
    'normal',
    'compact'
  ]),
  isHidden: PropTypes.bool
}

export default DiscountBadge
