import React, {
  useState,
  useEffect,
  useRef
} from 'react'
import {
  useTransition,
  animated
} from 'react-spring'

// Components
import FileType from './FileType'

let id
function useInterval(callback, delay, isRunning) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null && isRunning) {
      id = setInterval(tick, delay)

      return () => {
        clearInterval(id)
        id = null
      }
    }
  }, [delay, isRunning])
}

const types = [
  {
    id: 0,
    name: 'Sketch',
    description: 'Available for Sketch as vector layers. Fun fact: Jollycons has been designed using Sketch.',
    color: 'FFB331'
  },
  {
    id: 1,
    name: 'Figma',
    description: 'Jollycons 💜 Figma and rightfully so! Every single icon is at your disposal as a vector layer.',
    color: '0ACF83'
  },
  {
    id: 2,
    name: 'IconJar',
    description: 'Drag-and-drop any Jollycons icon straight from IconJar. Every icon has been neatly tagged for discoverability.',
    color: '55C0FF'
  },
  {
    id: 3,
    name: 'Adobe Illustrator',
    description: 'Of course Jollycons is also available as .ai Illustrator file. Perfect in designs for anything else than a screens.',
    color: 'F87800'
  },
  {
    id: 4,
    name: 'Adobe XD',
    description: 'Use Jollycons in Adobe XD to brighten up your interaction designs with pixel perfect icons.',
    color: 'F826B7'
  }
]

function FileTypesUsp() {
  const [activeFileTypeIndex, setActiveFileTypeIndex] = useState(0)
  const [isRunning, setIsRunning] = useState(true)

  const transitions = useTransition(types[activeFileTypeIndex], item => item.id, {
    from: { position: 'relative', opacity: 0 },
    enter: { opacity: 1 },
    leave: { position: 'absolute', opacity: 0 }
  })

  useInterval(() => {
    setActiveFileTypeIndex(activeFileTypeIndex === (types.length - 1) ? (
      0
    ) : (
      activeFileTypeIndex + 1
    ))
  }, 2500, isRunning)

  return (
    <div className="file-types-usp">
      <div className="column-types">
        <div className="types-bar-container">
          <div className="types-bar">
            {
              types.map(({ name, color }, idx) => (
                <FileType
                  key={idx}
                  index={idx}
                  name={name}
                  color={color}
                  handleInterval={setIsRunning}
                  setActiveFileTypeIndex={setActiveFileTypeIndex}
                  currentActive={activeFileTypeIndex}
                />
              ))
            }
          </div>

          {
            ['left', 'middle', 'right'].map((position, idx) => (
              <img
                key={idx}
                src={require(`../../images/files/usp/dots/${position}.svg`)}
                alt={`Dots ${position}`}
                className={`dots ${position}`}
              />
            ))
          }
        </div>
      </div>
      <div className="column-text">
        <h3>Works with your favorite tool</h3>
        <p className="streamer">
          {
            transitions.map(({
              item,
              props,
              key
            }) => (
              <animated.span
                key={key}
                style={{ ...props }}
              >
                {`${item.description}`}
              </animated.span>
            ))
          }
        </p>
      </div>
    </div>
  )
}

export default FileTypesUsp
