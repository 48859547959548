import anime from 'animejs'

/**
 * Settings for changing the svg change animation.
 * @property {number} startDelay - MS before the svg animation plays.
 * @property {number} changeDelay - MS before the svg unmounted and the next mount
 * @property {number} fadeOutDuration - MS how long should the fade out animation take.
 * @property {number} fadeOutDelay - MS before the svg starts fading out.
 */
export const animationChangeConfig = {
  startDelay: 150,
  changeDelay: 450,
  fadeOutDuration: 300,
  fadeOutDelay: 250
}

export function animateIn(target) {
  anime({
    targets: target,
    opacity: 1,
    duration: 150,
    easing: 'easeInSine'
  })
}
