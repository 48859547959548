import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

// Helper(s)
import { getProductID } from '../utils/helpers'

// Components
import TopCard from './TopCard'

// Default slider styling
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

const TopCardsSlider = ({
  topCardData,
  lineItems,
  products,
  type
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 0,
    centerMode: true
  }

  return (
    <div className="topcards topcards-slider">
      <Slider {...settings}>
        {
          topCardData.map((data, idx) => (
            <TopCard
              {...data}
              lineItems={lineItems}
              id={!data.comingSoon && getProductID(data.slug, products)}
              comingSoon={data.comingSoon}
              price={data.price}
              key={idx}
              type={type}
              isCarouselItem
            />
          ))
        }
      </Slider>
    </div>
  )
}

TopCardsSlider.defaultProps = {
  type: 'overview'
}

TopCardsSlider.propTypes = {
  lineItems: PropTypes.array,
  products: PropTypes.array,
  topCardData: PropTypes.any,
  type: PropTypes.string
}

export default TopCardsSlider
