import React, {
  useState,
  useEffect,
  useRef
} from 'react'

import FlexibleVectors from './FlexibleVectors'
import FriendlyStyle from './FriendlyStyle'
import PixelPerfect from './PixelPerfect'

import { animationChangeConfig } from './helpers'

const Animations = () => {
  const [activeAnimation, setActiveAnimation] = useState('flexible')
  const nextAnimationTimeout = useRef(false)

  useEffect(() => {
    return () => {
      clearTimeout(nextAnimationTimeout.current)
      nextAnimationTimeout.current = null
    }
  }, [])

  function handleAnimationComplete(nextAnimation) {
    nextAnimationTimeout.current = setTimeout(() => {
      setActiveAnimation(nextAnimation)
    }, animationChangeConfig.changeDelay)
  }

  const animationElements = {
    flexible: <FlexibleVectors onComplete={handleAnimationComplete} />,
    pixelPerfect: <PixelPerfect onComplete={handleAnimationComplete} />,
    friendly: <FriendlyStyle onComplete={handleAnimationComplete} />,
  }

  return (
    <React.Fragment>
      {
        animationElements[activeAnimation]
      }
    </React.Fragment>
  )
}

export default Animations
