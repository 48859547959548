import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import OverviewSetCard from './OverviewSetCard'
import LoadMoreSetsToggle from './LoadMoreSetsToggle'
import Link from './Link'

const OverviewSets = ({ data, handleError }) => {
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [cards, setCards] = useState([])
  const [products, setProducts] = useState(data)

  let defaultWidth

  if (typeof window !== 'undefined') {
    defaultWidth = window.innerWidth
  }

  useEffect(() => {
    try {
      const filteredProducts = data.allContentfulIconSet.edges

      setProducts(filteredProducts)

      const cards = []

      filteredProducts.forEach(({
        node: {
          title,
          id,
          slug
        }
      }) => {
        cards.push(<OverviewSetCard
          title={title}
          slug={slug}
          handleError={handleError}
          key={id}
        />)
      })

      setCards(cards)
    } catch (err) {
      console.error('Error', err)

      handleError(err)
    }
  }, [data])

  return (
    <div className="container-md overview-sets-container">
      <div className="seperator-line" />

      <h4>
        Super complete
      </h4>
      <p className="overview-sets__description">
        An overview of all icons grouped by category.
      </p>

      <div className="overview-grid">
        {
          defaultWidth > 414 ? (
            cards
          ) : (
            isMobileOpen ? (
              cards
            ) : (
              <React.Fragment>
                {cards.slice(0, 6)}

                <LoadMoreSetsToggle
                  amountOfSets={products.length - 6}
                  toggle={setIsMobileOpen}
                />
              </React.Fragment>
            )
          )
        }
      </div>

      <Link className="text-link violet small" to="/categories">
        View Categories
      </Link>
    </div>
  )
}

OverviewSets.propTypes = {
  data: PropTypes.shape({
    allContentfulIconSet: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          title: PropTypes.string,
          slug: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired
        }).isRequired
      })).isRequired
    }).isRequired
  }),
  handleError: PropTypes.func.isRequired
}

OverviewSets.defaultProps = {
  data: {
    allContentfulIconSet: {
      edges: []
    }
  }
}

export default OverviewSets
