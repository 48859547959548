import React from 'react'
import { LayoutContextConsumer } from './context'

const withContext = Page => (
  props => (
    <LayoutContextConsumer>
      {value => (
        <Page {...{ ...props, ...value }} />
      )}
    </LayoutContextConsumer>
  )
)

export default withContext
