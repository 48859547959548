import React from 'react'
import PropTypes from 'prop-types'

const LoadMoreSetsToggle = ({ amountOfSets, toggle }) => (
  <button
    className="overview-sets__load-more-toggle"
    onClick={() => toggle(true)}
    type="button"
  >
    <p>
      Load
      {` ${amountOfSets} `}
      more
      <span className="underline" aria-hidden />
    </p>
  </button>
)

LoadMoreSetsToggle.propTypes = {
  amountOfSets: PropTypes.number.isRequired,
  toggle: PropTypes.func.isRequired
}

LoadMoreSetsToggle.defaultProps = {
  amountOfSets: 0,
  toggle: () => {}
}

export default LoadMoreSetsToggle
