import React from 'react'
import PropTypes from 'prop-types'

// Helpers
import { getProductID } from '../utils/helpers'

// Components
import TopCard from './TopCard'

const TopCards = ({
  topCardData,
  lineItems,
  products,
  type
}) => (
  <div className="topcards">
    {
      topCardData.map((data, idx) => (
        <TopCard
          {...data}
          lineItems={lineItems}
          id={!data.comingSoon && getProductID(data.slug, products)}
          comingSoon={data.comingSoon}
          price={data.price}
          key={idx}
          type={type}
        />
      ))
    }
  </div>
)

TopCards.defaultProps = {
  type: 'overview'
}

TopCards.propTypes = {
  lineItems: PropTypes.array,
  products: PropTypes.array,
  topCardData: PropTypes.any,
  type: PropTypes.string
}

export default TopCards
