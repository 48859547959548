import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

// Component(s)
import Link from './Link'

// Helper(s)
import { handleProductClick, truncateSetName } from '../utils/helpers'

function TopCard(props) {
  const {
    colorType,
    title = '',
    slug,
    price = '8',
    comingSoon,
    type,
    isCarouselItem
  } = props

  const [isCardActive, setCardActive] = useState(false)

  return (
    <div
      className={`top-card ${colorType}`}
      onClick={e => handleProductClick(e, slug, '.btn')}
      onMouseOver={() => setCardActive(true)}
      onMouseOut={() => setCardActive(false)}
      role="button"
      tabIndex={0}
    >
      <div className={`content ${isCardActive ? 'active' : ''}`}>
        <div className={`label ${comingSoon && 'coming-soon'}`}>
          {
            comingSoon ? (
              <img
                // @ts-ignore
                src={require('../images/categories/coming-soon/coming-soon-small-related.svg')}
                alt="Coming soon"
              />
            ) : 'Category'
          }
        </div>
        <div className="title">{ truncateSetName(title, 16) }</div>
        <div className="icons-set">
          <img
            src={
              comingSoon ? (
                require(`../images/categories/coming-soon/overview-featured-${colorType}-coming-soon@2x.png`)
              ) : (
                require(`../images/categories/${slug}/overview-featured-${colorType}-${slug}@2x.png`)
              )
            }
            alt={slug}
          />
        </div>
      </div>

      <CSSTransition
        {...{ classNames: 'toggle-animation' }}
        in={isCardActive || isCarouselItem}
        unmountOnExit
        style={{ transitionDelay: '200ms' }}
        timeout={0}
      >
        <div className={`action-bar ${comingSoon ? 'coming-soon' : ''} ${type === 'home' ? 'home' : ''}`}>
          {
            isCarouselItem ? (
              type === 'home' && (
                <Link className="text-link white center" to={`/categories/${slug}`}>View</Link>
              )
            ) : (
              <Link className="text-link white center" to={`/categories/${slug}`}>View</Link>
            )
          }

          {
            (!comingSoon && type !== 'home') && (
              <button
                className="btn btn-basket"
                onClick={() => console.log('onclick')}
                type="button"
              >
                <span>
                  Buy Category
                  <span className="price">
                    &#36;
                    {price}
                  </span>
                </span>
              </button>
            )
          }
        </div>
      </CSSTransition>
    </div>
  )
}

TopCard.propTypes = {
  colorType: PropTypes.string.isRequired,
  comingSoon: PropTypes.bool.isRequired,
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  slug: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  isCarouselItem: PropTypes.bool
}

export default TopCard
