import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'

// Components
import TopCards from './TopCards'
import TopCardsSlider from './TopCardsSlider'

function TopSellingCards(props) {
  const {
    topCardData,
    lineItems,
    products,
    type = 'overview'
  } = props

  const isMobile = useMedia({
    maxWidth: 425
  })

  return (
    <section className="top-selling-cards-section">
      <div className="container-md">
        <div className="margin-xl">
          {
            type !== 'home' && (
              <div>
                <h1 className="dark text-center">Categories</h1>
                <p className="streamer text-center margin-xxl-bottom narrow">Jollycons has over 35 categories that can be bought individually</p>
              </div>
            )
          }

          <div className="top-selling-cards-container">
            {
              type === 'home'
                ? <h3 className="dark text-center margin-l-bottom">Soft &amp; friendly throughout every category</h3>
                : <p className="top-label margin-l-bottom">top selling categories</p>
            }

            {
              isMobile ? (
                <TopCardsSlider
                  type={type}
                  lineItems={lineItems}
                  products={products}
                  topCardData={topCardData}
                />
              ) : (
                <TopCards
                  type={type}
                  lineItems={lineItems}
                  products={products}
                  topCardData={topCardData}
                />
              )
            }
          </div>
        </div>
      </div>
    </section>
  )
}

TopSellingCards.propTypes = {
  lineItems: PropTypes.array,
  products: PropTypes.array,
  topCardData: PropTypes.any,
  type: PropTypes.string
}

export default TopSellingCards
