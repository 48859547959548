import React from 'react'

function GeneralUsps() {
  return (
    <div className="container-md general-usps">
      <div className="dots">
        {/* @ts-ignore */}
        <img className="dot blue" src={require('../images/general-usps/dots/blue.svg')} alt="dots" />
        {/* @ts-ignore */}
        <img className="dot green" src={require('../images/general-usps/dots/green.svg')} alt="dots" />
        {/* @ts-ignore */}
        <img className="dot red" src={require('../images/general-usps/dots/red.svg')} alt="dots" />
      </div>

      <div className="grid yg around text-center usps">
        <div className="col-4 usp">
          <div className="usp-icon">
            {/* @ts-ignore */}
            <img src={require('../images/general-usps/perfect-details.svg')} alt="Perfect details" />
          </div>
          <h4>Simply precise</h4>
          <p>
            {'Every icon has been designed to have the the perfect amount of details while staying easily recognizable.'}
          </p>
        </div>
        <div className="col-4 usp">
          <div className="usp-icon">
            {/* @ts-ignore */}
            <img src={require('../images/general-usps/flexible-vectors.svg')} alt="Flexible vectors" />
          </div>
          <h4>Flexible vectors</h4>
          <p>
            {'All icons are flexible vector graphics which makes them highly suitable for scaling, tweaking and customization.'}
          </p>
        </div>
        <div className="col-4 usp">
          <div className="usp-icon">
            {/* @ts-ignore */}
            <img src={require('../images/general-usps/file-formats.svg')} alt="File formats" />
          </div>
          <h4>In eight file formats!</h4>
          <p>
            {'Jollycons comes as standalone PNGs, SVGs, and PDFs and as .sketch, .xd, .fig, .ai and .iconjar files.'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default GeneralUsps
