import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Utils
import { titleToDash } from '../../utils/helpers'

function FileType({
  name,
  color,
  currentActive,
  index,
  handleInterval,
  setActiveFileTypeIndex
}) {
  const [isTooltipActive, setTooltipActive] = useState(false)

  function showTooltip() {
    setTooltipActive(true)
    handleInterval(false)
    setActiveFileTypeIndex(index)
  }

  function hideTooltip() {
    setTooltipActive(false)
    handleInterval(true)
  }

  useEffect(() => {
    setTooltipActive(index === currentActive)
  }, [currentActive])

  return (
    <div
      className="type"
      onMouseOver={showTooltip}
      onMouseOut={hideTooltip}
    >
      <div className={`tooltip ${isTooltipActive ? 'active' : ''}`}>{name}</div>

      <img
        src={require(`../../images/files/usp/${titleToDash(name)}.svg`)}
        alt={`${name} illustration`}
        className="type-image"
      />

      <div
        className={`indicator-dot ${isTooltipActive ? 'active' : ''}`}
        style={{
          backgroundColor: `#${color}`
        }}
      />
    </div>
  )
}

FileType.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  currentActive: PropTypes.number,
  index: PropTypes.number,
  setActiveFileTypeIndex: PropTypes.func,
  handleInterval: PropTypes.func
}

export default FileType
